import { CheckIcon, InformationCircleIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageStripeCredential } from "src/actions/site";
import { USDIcon } from "src/components/BillingAndPayments/StripeCountryIcons";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";

const StripeSettings = ({ site, ...props }) => {
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [stripeCurrency, setStripeCurrency] = useState("USD");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [sendEmail, setSendEmail] = useState(false);

  const {
    response: { data: billingManagers },
    status: { done: isDone },
    refreshData,
  } = useFetch(`/settings/billing/stripe/customers`);

  const resetInput = () => {
    setStripePublishableKey("");
    setStripeSecretKey("");
    setStripeCurrency("USD");
  };

  const handleOnModalOpen = () => {
    if (isDone) {
      if (billingManagers?.length > 0 && stripeSecretKey) {
        setIsModalOpen(true);
      } else {
        handleStripeCredential();
      }
    }
  };

  const handleStripeCredential = async () => {
    try {
      setIsDisabled(true);
      const message = await props.manageStripeCredential({ send_email: sendEmail, stripe_publishable_key: stripePublishableKey, stripe_secret_key: stripeSecretKey, stripe_currency: stripeCurrency.toLocaleLowerCase() });
      toast.success(message);
      setIsModalOpen(false);
      refreshData();
      resetInput();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <>
      <EditContainer
        title="Connect Stripe"
        preview={
          <div className="flex h-10 w-56 items-center">
            <p className={classNames("flex gap-x-2 rounded px-2 py-1 opacity-60", !site?.stripe_publishable_key && site?.stripe_secret_key ? "bg-gray-50" : "bg-green-50 text-green-700")}>
              {!site?.stripe_publishable_key && site?.stripe_secret_key ? (
                <>
                  <NoSymbolIcon className="h-5 w-5" /> Disabled
                </>
              ) : (
                <>
                  <CheckIcon className="h-5 w-5" /> Enabled
                </>
              )}
            </p>
          </div>
        }
        defaultOpen={true}
        isLoading={isDisabled}
        onSuccess={handleOnModalOpen}
        onCancel={resetInput}>
        <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
          <div className="min-w-[260px] px-2">
            <div className="mb-2 ml-3 mt-6 flex">
              <InformationCircleIcon className="mr-1 h-5 w-5" />
              <p>
                Need more information about credentials? Visit the Stripe{" "}
                <a
                  className="text-blue-600 underline visited:text-purple-600 hover:text-blue-800"
                  href="https://support.stripe.com/questions/what-are-stripe-api-keys-and-how-to-find-them">
                  help page.
                </a>
              </p>
            </div>
            <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
              <div className="w-full px-2">
                <Input
                  type="password"
                  name="stripe-publishable_key"
                  label="Publishable key"
                  inline={true}
                  value={stripePublishableKey}
                  placeholder={site?.stripe_publishable_key}
                  onChange={(e) => setStripePublishableKey(e.target.value)}
                />
              </div>
              <div className="w-full px-2">
                <Input
                  type="password"
                  name="stripe-secret_key"
                  label="Secret key"
                  inline={true}
                  value={stripeSecretKey}
                  placeholder={site?.stripe_secret_key}
                  onChange={(e) => setStripeSecretKey(e.target.value)}
                />
              </div>
              <div className="relative w-full px-2">
                <Input
                  name="stripe-currency"
                  label="Currency"
                  disabled={true}
                  inline={true}
                  value={stripeCurrency}
                  onChange={(e) => setStripeCurrency(e.target.value)}
                />
                <div className="absolute left-14 top-[19px] opacity-40">
                  <USDIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </EditContainer>
      <Modal
        title="Stripe Credential"
        secondaryTitle="update"
        size="lg"
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSuccess={handleStripeCredential}
        isLoading={isDisabled}
        defaultOptions={{
          onSuccessButtonText: "Submit",
        }}>
        <InformationAlert
          type="warning"
          content={
            <div className="text-sm">
              If you change the credentials, the card details of the listed billing manager for each workspace will be deleted. Additionally, any workspace with an active plan will expire at the next billing period. To continue their subscription, the billing manager of each
              assigned workspace must add at least one card.
            </div>
          }
        />
        <div className="my-2 flex w-full flex-col overflow-hidden rounded-md border">
          <div className="grid grid-cols-2 border-b bg-slate-50 p-2 text-sm font-semibold">
            <div className="">Workspace</div>
            <div>Billing Manager</div>
          </div>
          {billingManagers?.map((item, i) => {
            return (
              <div
                key={i}
                className="grid grid-cols-2 border-b bg-slate-50 p-2 text-xs">
                <div className="">{item?.name}</div>
                <div>{item?.billing_elegible_user_id?.name || "N/A"}</div>
              </div>
            );
          })}
        </div>
        <div>
          <Checkbox
            checkboxLabel="Send email to the billing manager"
            onChange={(e) => setSendEmail(e.target.checked)}
          />
        </div>
        <div className="mt-2 border-t pt-2">Are you sure you want to change the credential?</div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageStripeCredential })(StripeSettings);
