import { ClipboardDocumentListIcon, CodeBracketIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { domoIcon, metabaseIcon, quicksightIcon } from "src/components/Pages/PageContainerIcons";

export const newPagaJsonArr = (workspaceId, isGlobal) => {
  return {
    _id: "",
    name: "",
    page_type: "DOMO",
    embed_type: "EMBED_PAGE",
    platform_embed: null,
    ignore_global_filters: false,
    bordered: false,
    titled: false,
    theme: "",
    credential_id: null,
    form_id: null,
    embed_id: "",
    cache_select_columns: false,
    columns: [],
    dashboard_id: "",
    custom_html: "",
    page_link: "",
    page_link_newtab: false,
    workspace_id: workspaceId || null,
    is_global: isGlobal,
    navigation_blocks: [
      {
        title: "",
        image: "",
        height: "",
        width: "",
        links: [
          {
            link_text: "",
            page_id: null,
          },
        ],
      },
    ],
    nested_pages: [],
  };
};

export const pageTypes = {
  DOMO: (
    <div className="flex items-center gap-x-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none">
        <rect
          width="19"
          height="19"
          fill="url(#pattern0_2279_3)"
        />
        <defs>
          <pattern
            id="pattern0_2279_3"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1">
            <use
              href="#image0_2279_3"
              transform="scale(0.00333333)"
            />
          </pattern>
          <image
            id="image0_2279_3"
            width="300"
            height="300"
            href={domoIcon}
          />
        </defs>
      </svg>
      Domo Embed
    </div>
  ),
  QUICKSIGHT: (
    <div className="flex items-center gap-x-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none">
        <rect
          width="19"
          height="19"
          fill="url(#pattern0_2278_2)"
        />
        <defs>
          <pattern
            id="pattern0_2278_2"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1">
            <use
              href="#image0_2278_2"
              transform="scale(0.00390625)"
            />
          </pattern>
          <image
            id="image0_2278_2"
            width="256"
            height="256"
            href={quicksightIcon}
          />
        </defs>
      </svg>
      QuickSight Embed
    </div>
  ),
  METABASE: (
    <div className="flex items-center gap-x-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none">
        <rect
          x="2"
          width="15.02"
          height="19"
          fill="url(#pattern0_2279_7)"
        />
        <defs>
          <pattern
            id="pattern0_2279_7"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1">
            <use
              href="#image0_2279_7"
              transform="scale(0.000617665 0.000488281)"
            />
          </pattern>
          <image
            id="image0_2279_7"
            width="1619"
            height="2048"
            href={metabaseIcon}
          />
        </defs>
      </svg>
      Metabase Embed
    </div>
  ),
  FORM: (
    <div className="flex items-center gap-x-3">
      <ClipboardDocumentListIcon className="h-6 w-6" />
      Form
    </div>
  ),
  CUSTOM_HTML: (
    <div className="flex items-center gap-x-3">
      <CodeBracketIcon className="h-6 w-6" />
      Custom HTML
    </div>
  ),
  EXTERNAL_LINK: (
    <div className="flex items-center gap-x-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="h-6 w-6">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
        />
      </svg>
      External Link
    </div>
  ),
};

export const credentialTypes = (userId) => {
  return {
    DEFAULT: (credential) => (
      <div className="flex flex-col gap-x-3">
        <div className="flex items-center gap-x-3">
          {credential.name} <div className="!h-5 rounded-md bg-gray-200 px-2 text-[10px] font-semibold leading-5 text-gray-500">Default</div>
        </div>
        <p className="-mt-[2px] text-xs text-gray-400">{credential.description}</p>
      </div>
    ),
    NORMAL: (credential) => (
      <div className="flex flex-col gap-x-3">
        {credential.name}
        <p className="flex text-xs text-gray-400 gap-x-2">
          {credential.description}{" "}
          {userId !== credential?.created_by && (
            <span className="flex items-center gap-x-1 text-xs font-light text-gray-400">
              <ExclamationTriangleIcon className="h-4 w-4" />
              Created by another account.
            </span>
          )}
        </p>
      </div>
    ),
  };
};

export const pageCreatePayload = (newPage, nestedAllPages, selectedWorkspace, workspaceDetails, user) => {
  return {
    is_global: newPage.is_global,
    workspace_id: newPage.workspace_id ? [newPage.workspace_id] : selectedWorkspace,
    credential_id: newPage.credential_id,
    form_id: newPage.form_id,
    page_type: newPage.page_type,
    custom_html: newPage.custom_html,
    page_link: newPage.page_link,
    page_link_newtab: newPage.page_link_newtab,
    created_at: "",
    created_by: user?._id,
    deleted_at: null,
    deleted_by: null,
    embed_id: newPage.embed_id,
    platform_embed: newPage.platform_embed,
    dashboard_id: newPage.dashboard_id,
    embed_type: newPage.embed_type,
    ignore_global_filters: newPage.ignore_global_filters,
    cache_select_columns: newPage.cache_select_columns,
    columns: newPage.columns,
    bordered: newPage.bordered,
    titled: newPage.titled,
    theme: newPage.theme,
    name: newPage.name,
    navigation_blocks: newPage.navigation_blocks,
    updated_at: "",
    updated_by: user?._id,
    nested_pages:
      newPage.page_type === "DOMO" && newPage.embed_type === "NESTED_EMBED"
        ? !workspaceDetails
          ? newPage.nested_pages
              .filter((item) => (item.page_id !== null ? nestedAllPages.map((page) => page._id).includes(item.page_id) : item !== null))
              .filter((item) => item.page_id)
              .map((item) => item.page_id)
          : newPage.nested_pages
              .filter((item) => (item.page_id !== null ? (workspaceDetails.pages || []).map((page) => page.page_id).includes(item.page_id) : item !== null))
              .filter((item) => item.page_id)
              .map((item) => item.page_id)
        : [],
  };
};
