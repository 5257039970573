import { Menu, Transition } from "@headlessui/react";
import { BeakerIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { bottom } from "@popperjs/core";
import { Fragment, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";
import { classNames } from "src/helpers/classNames";
import Search from "src/components/Shared/Forms/Inputs/Search";
import LazyImage from "src/components/Shared/LazyImage";
import Logo from "src/assets/images/logo.png";

const DropdownWrapper = ({ data, buttonClass, currentId, buttonLabelClass, buttonIcon, buttonLabel, dropdownContainer, xPlacement = "left", content, onClick = () => {} }) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const [keyword, setKeyword] = useState("");
  const [filteredWorkspaces, setFilteredWorkspaces] = useState(data);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement || ""],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  const handleFilter = (searchText) => {
    let updatedFilteredWorkspaces = data.filter((filteredWorkspace) => filteredWorkspace.name?.toLowerCase().includes(searchText.toLowerCase()));
    setFilteredWorkspaces(updatedFilteredWorkspaces);
  };

  return (
    <>
      <Menu
        as="div"
        className="relative text-left ">
        <div className="group">
          <Menu.Button
            ref={setTargetElement}
            className={classNames("group flex items-center rounded-md px-3 transition-all", buttonClass)}>
            {content ? content : <div className={classNames("text-sm font-medium text-headerTextColor transition-all 2xl:text-base", buttonLabelClass)}>{buttonLabel}</div>}
            {buttonIcon && <ChevronDownIcon className="ml-1 h-5 w-5 flex-shrink-0 text-headerTextColor transition-all group-hover:stroke-headerTextColor group-hover:stroke-2" />}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          beforeEnter={() => setPopperElement(popperElRef.current)}
          afterLeave={() => setPopperElement(null)}
          ref={popperElRef}
          style={styles.popper}
          {...attributes.popper}>
          <Menu.Items className={classNames("absolute min-w-[200px] rounded-md border border-gray-100 bg-white shadow-md focus:outline-none md:min-w-[300px]", dropdownContainer)}>
            <>
              {data.length > 10 && (
                <div className={classNames("flex-shrink-0 bg-white px-2 pt-3")}>
                  <Search
                    keyword={keyword}
                    placeholder="Search"
                    setKeyword={(e) => {
                      setKeyword(e);
                      handleFilter(e);
                    }}
                  />
                </div>
              )}
              <div className="mb-1 mt-1">
                <p className={classNames("inline rounded-full bg-gray-50 px-2 py-1 text-xs text-gray-400", filteredWorkspaces.length > 10 ? "ml-3 mt-1" : "ml-3")}>
                  ({filteredWorkspaces.length}) workspace{filteredWorkspaces.length === 1 ? "" : "s"} found
                </p>
              </div>
              <div className={classNames("max-h-[calc(100vh-240px)] space-y-1 overflow-y-auto")}>
                {filteredWorkspaces.map((item) =>
                  item.link ? (
                    <Menu.Item key={item._id}>
                      {({ active }) => (
                        <Link
                          to={item.link}
                          className={classNames("group flex w-full items-center px-3 text-sm transition-all hover:bg-gray-50 hover:text-highlightColor", active ? "bg-carnation-500 text-white" : "text-slate-900")}>
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ) : (
                    <Menu.Item key={item._id}>
                      {({ active }) => (
                        <button
                          className={classNames("text flex w-full items-center border border-transparent px-3 py-2 text-left font-medium transition-all hover:bg-gray-100", currentId === item.slug ? "bg-gray-50" : "")}
                          onClick={() => onClick(item)}>
                          <div className={classNames("relative mr-3 rounded-xl border-[4px]", currentId === item.slug ? "border-gray-200" : "border-transparent")}>
                            <div className="h-12 w-12 rounded-md bg-white p-[1px]">
                              <LazyImage
                                src={item.image || Logo}
                                alt={item.name}
                                width={"100%"}
                                height={"100%"}
                                effect={""}
                                classes={"object-contain rounded-md"}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <p>{item.name}</p>
                            {item.workspace_type === "JWT_FULL_EMBED" && (
                              <div className="inline-flex items-center gap-x-2 rounded-sm text-sm text-gray-400 opacity-50">
                                <BeakerIcon className="h-4 w-4" />
                                Full Access
                              </div>
                            )}
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  ),
                )}
              </div>
            </>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default DropdownWrapper;
