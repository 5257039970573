import { WindowIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { workspaceIntegrationCredentialMakeDefault } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import QuickMenu from "src/components/Shared/QuickMenu";
import { useDomo } from "src/context/DomoProvider";
import { classNames } from "src/helpers/classNames";
import { formatDate } from "src/helpers/formatDate";
import DomoIntegrationClient from "./IntegrationClients/DomoIntegrationClient";
import MetabaseIntegrationClient from "./IntegrationClients/MetabaseIntegrationClient";
import QuickSightIntegrationClient from "./IntegrationClients/QuickSightIntegrationClient";

const WorkspaceIntegrationClientsItem = ({
  size,
  workspaceId,
  user,
  integration,
  credential,
  editCredential = () => {},
  openCreatedByModal = () => {},
  removeCredential = () => {},
  makeDefaultButtonDisabled = false,
  setMakeDefaultButtonDisabled = () => {},
  refreshIntegrations = () => {},
  workspaces = [],
  openPageListModal = () => {},
  ...props
}) => {
  const [makeDefaultIsLoading, setMakeDefaultIsLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const { openPageImportModal, isImporting } = useDomo();

  const getAttributeValue = (name) => {
    return credential.embed_attributes.find((embed_attribute) => embed_attribute.key === name)?.value;
  };

  const makeDefault = async () => {
    try {
      setMakeDefaultButtonDisabled(true);
      setMakeDefaultIsLoading(true);
      await props.workspaceIntegrationCredentialMakeDefault({ integration_id: integration._id, workspace_id: workspaceId, credential_id: credential?._id });
      setMakeDefaultIsLoading(false);
      setMakeDefaultButtonDisabled(false);
      refreshIntegrations();
    } catch (error) {
      setMakeDefaultIsLoading(false);
      setMakeDefaultButtonDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    let items = [];
    // Edit Cred.
    items.push({
      name: "Edit",
      onClick: () =>
        editCredential(
          integration,
          integration?.embed_types.find((embed_type) => embed_type.type === credential?.integration_type),
          credential,
        ),
      color: "text-gray-800 hover:text-highlightColor",
    });
    // Manage Created By
    user?.type === "admin" && !credential?.user?.name && items.push({ name: "Manage Created By", onClick: () => openCreatedByModal(integration, credential), color: "text-gray-800 hover:text-highlightColor" });
    // Page Import
    integration?.code === "domo" &&
      (user?._id === credential.created_by || !!user?.default_user) &&
      !isImporting &&
      credential?.embed_attributes?.find((embed_attribute) => embed_attribute.key === `developer_token`)?.value > 0 &&
      items.push({ name: "Import select pages", onClick: () => openPageImportModal({ integrationId: integration._id, credentialId: credential?._id, workspaceId: credential?.workspace_id, workspaces }), color: "text-gray-800 hover:text-highlightColor" });
    // Delete Cred.
    items.push({ name: "Delete", onClick: () => removeCredential(integration._id, credential?._id), color: "text-red-500" });
    setMenuItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.type, integration, credential, workspaces.length, isImporting]);

  const [columns, setColumns] = useState(Array(6).fill(""));
  useEffect(() => {
    const isOwner = workspaceId && credential?.created_by?.toString() === user?._id?.toString();
    const compactMode = size === "compact";
    setColumns([
      classNames(
        "flex-shrink overflow-hidden",
        compactMode ? isOwner && "max-w-[calc(100vw-62rem)] lgx:max-w-[calc(100vw-80rem)] xl:max-w-[calc(100vw-86rem)] 2xl:max-w-[calc(100vw-110rem)]" : !isOwner && "max-w-[calc(100%-32rem)] lg:max-w-[calc(100%-39rem)] xl:max-w-[calc(100%-50rem)]",
      ),
      classNames("flex flex-shrink-0 gap-1", compactMode ? isOwner && "!ml-auto w-48 2xl:w-60" : !isOwner && "w-52 lg:w-60 !ml-auto xl:w-80 2xl:w-96"),
      classNames("flex-shrink-0", compactMode ? isOwner && "w-16 2xl:w-28" : !isOwner && "w-20 lg:w-32 xl:w-40 2xl:w-40"),
      classNames("flex-shrink-0", compactMode ? isOwner && "w-28 2xl:w-36" : !isOwner && "w-32 lg:w-40 xl:w-60 2xl:w-60"),
      classNames("flex justify-end flex-shrink-0", compactMode ? isOwner && "w-28 2xl:w-36" : !isOwner && "xl:w-36 2xl:w-36"),
      classNames("w-9 flex-shrink-0 !ml-1"),
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex w-full flex-col rounded-md border border-gray-50 bg-white py-1 pl-4 pr-1 shadow lg:h-12 lg:flex-row">
      <div className="flex w-full items-center space-x-4">
        {integration?.code === "domo" && (
          <DomoIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
            classnameDisplay={columns[0]}
            classnameIntergrationType={columns[1]}
          />
        )}
        {integration?.code === "quicksight" && (
          <QuickSightIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
            classnameDisplay={columns[0]}
            classnameIntergrationType={columns[1]}
          />
        )}
        {integration?.code === "metabase" && (
          <MetabaseIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
            classnameDisplay={columns[0]}
            classnameIntergrationType={columns[1]}
          />
        )}

        <div className={columns[2]}>
          <Button
            version="default"
            type="button"
            onClick={() => (credential?.pages?.length > 0 ? openPageListModal(credential?.pages) : {})}
            className={classNames("flex items-center justify-center gap-1 text-sm", credential?.pages?.length > 0 ? "text-gray-900" : "text-gray-300")}>
            <WindowIcon className="h-5 w-5" /> {credential?.pages?.length || 0}
          </Button>
        </div>
        <div className={columns[3]}>
          {credential?.user?.name ? <div className="text-sm font-semibold text-gray-800">{credential?.user?.name}</div> : <div className="text-sm font-semibold text-red-500">Unknown User</div>}
          <div className="flex items-center text-xs text-gray-400">Created {formatDate(credential?.created_at, "MM/DD/YYYY")}</div>
        </div>
        {workspaceId && credential?.created_by?.toString() === user?._id?.toString() && (
          <div className={columns[4]}>
            <Button
              disabled={makeDefaultButtonDisabled || credential?.default_users_credential?.includes(user?._id)}
              loading={makeDefaultIsLoading}
              onClick={makeDefault}
              version={credential?.default_users_credential?.includes(user?._id) ? "default" : "gray"}
              className={classNames("flex !h-9 items-center justify-center whitespace-nowrap rounded-md !px-2 text-xs font-semibold leading-5", credential?.default_users_credential?.includes(user?._id) ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}>
              {credential?.default_users_credential?.includes(user?._id) ? "Default" : "Set as default"}
            </Button>
          </div>
        )}
        <div className={columns[5]}>
          <QuickMenu
            xPlacement="bottomRight"
            flip={false}
            items={menuItems}
            buttonClass="!w-9 !aspect-square !flex-shrink-0 !rounded-md !p-0"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialMakeDefault })(WorkspaceIntegrationClientsItem);
