import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getPageCredentialOptions } from "src/actions/page";
import { apiRequest } from "src/async/apiUtils";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import Modal from "src/components/Shared/Modal";
import { H5 } from "src/components/Shared/Text/Headers";
import { noLogo } from "src/config/host";
import { objectToFormData } from "src/helpers";
import useFetch from "src/hooks/useFetch";

const WorkspacesAddModal = ({ site, isOpen, onCancel = () => {}, onSuccess = () => {}, getPageCredentialOptions, credentials = [] }) => {
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];
  const [disabled, setDisabled] = useState(false);
  const [workspaceTypeName, setWorkspaceTypeName] = useState("");
  const [workspaceType, setWorkspaceType] = useState("IFRAME_EMBED");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [plans, setPlans] = useState([]);

  const [name, setName] = useState("");
  const [squareLogo, setSquareLogo] = useState(null);

  const [, setIsAvailableEditEmbedSettings] = useState(false);

  const {
    response: { data: integrations },
  } = useFetch("/pages/integration");

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await apiRequest("get", "/plans");
        setPlans(response?.data?.data);
        setSelectedPlan(response?.data?.data?.find((item) => item?.default)?._id);
      } catch (err) {}
    };
    if (site?.stripe_secret_key && site?.stripe_publishable_key) fetchPlans();
  }, [site?.stripe_secret_key, site?.stripe_publishable_key]);

  const loadPageCreds = async () => {
    // setCredLoading(true);
    const ac = new AbortController();
    try {
      await getPageCredentialOptions({}, ac.signal);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
    return () => ac.abort();
  };

  useEffect(() => {
    loadPageCreds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (const credential of credentials) {
      if (credential?.jwt_secret_count > 0 && credential?.embed_url && credential?.key_attribute) {
        setIsAvailableEditEmbedSettings(true);
      } else {
        setIsAvailableEditEmbedSettings(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const updateWorkspaceType = () => {
    let type = "IFRAME_EMBED";
    if (workspaceType === "IFRAME_EMBED") {
      type = "JWT_FULL_EMBED";
    }
    setWorkspaceType(type);
  };

  const createWorkspace = async () => {
    try {
      setDisabled(true);
      const updatedPayload = objectToFormData({
        name,
        plan_id: selectedPlan,
        square_logo: squareLogo,
        workspace_type: workspaceType,
      });
      const { data } = await apiRequest("POST", `/workspaces/add`, { body: updatedPayload }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {}, headers: { "Content-Type": "multipart/form-data" } });
      if (data.status === 200) {
        onSuccess({ workspace_id: data?.data?.workspace_id });
        setName("");
        setSquareLogo("");
      }

      setTimeout(() => setDisabled(false), 300);
    } catch (error) {
      setTimeout(() => setDisabled(false), 300);
    }
  };

  useEffect(() => {
    if (workspaceType) {
      setWorkspaceTypeName(workspaceType.charAt(0).toUpperCase() + workspaceType.slice(1).toLowerCase().replace("_", " "));
    }
  }, [workspaceType]);

  const onClear = () => {
    setName("");
    setSquareLogo("");
    setWorkspaceType("IFRAME_EMBED");
  };

  useEffect(() => {
    if (!isOpen) {
      onClear();
    }
  }, [isOpen]);

  const onImageChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        setSquareLogo(e.target.files[0]);
      }
    }
  };

  const ImageHandler = ({ image = "" }) => {
    let imageSource = useMemo(() => {
      let objUrl = "";

      if (image instanceof File) {
        objUrl = URL.createObjectURL(image);
      }

      return objUrl;
    }, [image]);

    const imageComponent = useCallback(() => {
      return (
        <div className={`group relative flex items-center gap-4`}>
          <>
            <div className={`flex h-16 w-16 cursor-pointer items-center justify-center overflow-hidden rounded-full border border-gray-200/60 bg-gray-300/40`}>
              {imageSource ? (
                <img
                  src={imageSource}
                  alt="Logo"
                  className="h-full w-full object-cover"
                  onError={(e) => (e.target.src = noLogo)}
                />
              ) : (
                <BuildingOffice2Icon className="h-8 w-8 stroke-2 text-gray-700" />
              )}
            </div>
            <div className="flex-shrink-0">
              <Button
                version="gray"
                className="relative overflow-hidden">
                <span className="text-sm text-gray-600">Upload image</span>
                <input
                  type="file"
                  className="absolute right-0 top-0 z-10 h-full w-96 cursor-pointer opacity-0"
                  onChange={(e) => onImageChange(e)}
                />
              </Button>
            </div>
          </>
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSource]);

    return imageComponent();
  };

  const ImageHandlerMemoized = useMemo(() => <ImageHandler image={squareLogo} />, [squareLogo]);

  return (
    <Modal
      title="Workspace"
      secondaryTitle={`Create ${workspaceTypeName}`}
      isOpen={isOpen}
      onCancel={() => {
        onCancel();
        onClear();
      }}
      onSuccess={createWorkspace}
      defaultOptions={{
        onSuccessButtonText: "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}>
      <div className="relative w-full space-y-4">
        <div className="flex items-end justify-between">
          <div className="flex-shrink">
            <div className="mb-2 text-base text-gray-700">
              Photo <small className="text-gray-400">(Optional)</small>
            </div>
            <div className="flex gap-x-7">{ImageHandlerMemoized}</div>
          </div>
        </div>
        <div className="relative mb-4 w-full">
          <Input
            name="name"
            label="Workspace name"
            inline={true}
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        {integrations?.length > 0 && (
          <TextAccordion
            headerTextPosition="left"
            headerText="Advanced details">
            <div className="flex flex-col gap-y-3 md:flex-row">
              <Toggle
                checked={workspaceType === "JWT_FULL_EMBED"}
                onChange={updateWorkspaceType}
              />
              <H5 caption="Embed a full BI tool for your clients to use.">Full JWT application Embed</H5>
            </div>
          </TextAccordion>
        )}
        {site?.stripe_secret_key && site?.stripe_publishable_key && (
          <>
            <div>
              <SelectMenu
                label="Select plan"
                inline={true}
                startIndex={plans?.findIndex((item) => item?._id === selectedPlan)}
                options={plans?.map((plan) => ({
                  Component: () => (
                    <div className="flex items-center gap-2 text-sm">
                      <span>{plan?.name}</span>
                      {plan?.default && <span className="rounded-lg border border-blue-300 bg-blue-100 px-1 text-[10px] text-blue-600">Default</span>}
                    </div>
                  ),
                  key: plan?.name,
                  value: plan?._id,
                }))}
                setOption={(option) => {
                  setSelectedPlan(option.value);
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    credentials: state.pageCredentialOptions,
    site: state.site,
  };
};

export default connect(mapStateToProps, { getPageCredentialOptions })(WorkspacesAddModal);
