import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import MultiSelectObject from "../Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import useMounted from "src/hooks/useMounted";
import TextAccordion from "../Shared/Accordions/TextAccordion";

const SettingsContainer = ({ defaultTitle = {}, isEnabled = false, setIsEnabled = () => {}, timeFrame = 0, setTimeFrame = () => {}, isButtonDisabled = false, onSubmit = () => {}, onCancel = () => {}, is2Fa = false, additionalData = {}, site }) => {
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false);

  const handleInputChange = (setValue, e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    setValue(newValue === "0" ? "" : newValue);
  };

  useEffect(() => {
    let isFormValid = false;

    if (!isEnabled) {
      isFormValid = true;
    }

    if (isEnabled && is2Fa && timeFrame && additionalData?.twoFactorOtpResendIntervalTimeframe) {
      isFormValid = true;
    }

    if (!is2Fa && timeFrame && isEnabled) {
      isFormValid = true;
    }

    if (additionalData?.twoFactorOtpBlockEnabled && (!additionalData?.twoFactorOtpFailureTimeframe || !additionalData?.twoFactorOtpFailedAttemptLimit)) {
      isFormValid = false;
    }

    setIsSaveBtnDisable(!isFormValid);
  }, [is2Fa, isEnabled, timeFrame, additionalData]);

  return (
    <EditContainer
      title={defaultTitle?.title}
      isDisabled={isSaveBtnDisable}
      preview={{
        style: "success",
        isSuccess: isEnabled,
        text: !isEnabled ? (
          <>
            <NoSymbolIcon className="h-5 w-5" /> Disabled
          </>
        ) : (
          <>
            <CheckIcon className="h-5 w-5" /> Enabled
          </>
        ),
      }}
      onSuccess={onSubmit}
      defaultOptions={{
        onSuccessLoaderVisible: isButtonDisabled,
        onSuccessLoaderStart: isButtonDisabled,
      }}
      onCancel={onCancel}>
      <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
        <div className="min-w-[260px] px-2 ">
          <div className="relative w-full">
            <ToggleHeader
              title={defaultTitle?.title}
              subtitle={defaultTitle?.subTitle}>
              <Toggle
                checked={isEnabled}
                onChange={(e) => {
                  setIsEnabled(e);
                }}
              />
            </ToggleHeader>
            {!is2Fa && isEnabled && (
              <div className="mb-4 flex w-full items-center gap-x-5 gap-y-3 font-normal ">
                <p>{defaultTitle?.inputFirstTitle}</p>
                <div className={`${defaultTitle?.inputBoxInlineLabel ? "w-[75px]" : "w-[55px]"}`}>
                  <Input
                    wrapperClass={isSaveBtnDisable ? "focus-within:!border-red-600" : ""}
                    label={defaultTitle?.inputBoxInlineLabel ? defaultTitle?.inputBoxInlineLabel : "day(s)"}
                    inline
                    name="user-settings"
                    disabled={!isEnabled}
                    value={timeFrame}
                    onChange={(e) => handleInputChange(setTimeFrame, e)}
                  />
                </div>
                <p>{defaultTitle?.inputSecondTitle}</p>
              </div>
            )}
            {is2Fa && isEnabled && (
              <div>
                <ToggleHeader
                  title={"Disable email (2FA) for Admin login"}
                  subtitle="Disable two-factor authentication for admin login security.">
                  <Toggle
                    checked={additionalData?.twoFactorDisabledAdmin}
                    onChange={(e) => {
                      additionalData?.setTwoFactorDisabledAdmin(e);
                    }}
                  />
                </ToggleHeader>
                <div className="mb-4 flex w-full items-center gap-x-5 gap-y-3 font-normal ">
                  <p>{defaultTitle?.inputFirstTitle}</p>
                  <div className="w-[55px] ">
                    <Input
                      label={"day(s)"}
                      inline
                      name="user-settings"
                      wrapperClass={!timeFrame ? "focus-within:!border-red-600" : ""}
                      disabled={!isEnabled}
                      value={timeFrame}
                      onChange={(e) => handleInputChange(setTimeFrame, e)}
                    />
                  </div>
                  <p>{defaultTitle?.inputSecondTitle}</p>
                </div>

                <div className="flex w-full items-center gap-x-5 gap-y-3 py-2 font-normal ">
                  <p>Resend OTP will be disabled for</p>
                  <div className="w-[70px]">
                    <Input
                      label={"second(s)"}
                      inline
                      name="user-settings"
                      wrapperClass={!additionalData?.twoFactorOtpResendIntervalTimeframe ? "focus-within:!border-red-600" : ""}
                      disabled={!isEnabled}
                      value={additionalData?.twoFactorOtpResendIntervalTimeframe}
                      onChange={(e) => handleInputChange(additionalData?.setTwoFactorOtpResendIntervalTimeframe, e)}
                    />
                  </div>
                  <p> after each request.</p>
                </div>

                <div className="mt-4">
                  <ToggleHeader
                    title={"Enable OTP Block on Failed 2FA Attempts"}
                    subtitle="Block OTP requests after multiple failed 2FA attempts to enhance account security">
                    <Toggle
                      checked={additionalData?.twoFactorOtpBlockEnabled}
                      onChange={(e) => {
                        additionalData?.setTwoFactorOtpBlockEnabled(e);
                      }}
                    />
                  </ToggleHeader>
                  {additionalData?.twoFactorOtpBlockEnabled && (
                    <div className="flex w-full items-center gap-x-5 gap-y-3 pt-2 font-normal">
                      <p>Disable verification for</p>
                      <div className="w-[70px]">
                        <Input
                          label={"minute(s)"}
                          inline
                          name="user-settings"
                          wrapperClass={!additionalData?.twoFactorOtpFailureTimeframe ? "focus-within:!border-red-600" : ""}
                          disabled={!isEnabled || !additionalData?.twoFactorOtpBlockEnabled}
                          value={additionalData?.twoFactorOtpFailureTimeframe}
                          onChange={(e) => handleInputChange(additionalData?.setTwoFactorOtpFailureTimeframe, e)}
                        />
                      </div>
                      <p> after</p>
                      <div className="w-[120px]">
                        <Input
                          label={"failed attempt(s)"}
                          inline
                          name="user-settings"
                          wrapperClass={!additionalData?.twoFactorOtpFailedAttemptLimit ? "focus-within:!border-red-600" : ""}
                          disabled={!isEnabled || !additionalData?.twoFactorOtpBlockEnabled}
                          value={additionalData?.twoFactorOtpFailedAttemptLimit}
                          onChange={(e) => handleInputChange(additionalData?.setTwoFactorOtpFailedAttemptLimit, e)}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {site?.manage_subdomains && site?.allow_subdomains && (
                  <div className="border-t">
                    <TextAccordion
                      iconPosition="left"
                      headerTextPosition="left"
                      headerText="Advanced Features ( Disable 2FA for wokspaces )"
                      contentClass="!border-none !overflow-visible">
                      <div className=" w-full max-w-2xl items-center gap-x-5  gap-y-3 font-normal">
                        <div className="text-sm font-medium  text-gray-400">Applies only to workspaces with specific subdomains.</div>
                        <MultiSelectObject
                          inline
                          defaultOptions={additionalData?.workspaces?.map((workspace) => {
                            return { key: workspace._id, value: workspace.name, selected: additionalData?.twoFactorDisabledWorkspaces.includes(workspace?._id), object: workspace };
                          })}
                          strictOnChangeMode={false}
                          searchableFields={["name"]}
                          drowdownWindowMaxWidth={"max-w-sm min-w-sm"}
                          dropdownButtonClass={"max-w-sm min-w-sm"}
                          title="Select workspaces to disable 2FA. "
                          onChange={(e) => {
                            let updatedSelectedWorkspaces = e.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
                            additionalData?.setTwoFactorDisabledWorkspaces(updatedSelectedWorkspaces);
                          }}
                        />
                      </div>
                    </TextAccordion>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </EditContainer>
  );
};

const UserSettings = ({ site, manageSiteSettings }) => {
  const [workspaces, setWorkspaces] = useState([]);

  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [twoFactorDisabledAdmin, setTwoFactorDisabledAdmin] = useState(false);
  const [twoFactorTimeframe, setTwoFactorTimeframe] = useState("");
  const [twoFactorOtpBlockEnabled, setTwoFactorOtpBlockEnabled] = useState(false);
  const [twoFactorOtpFailedAttemptLimit, setTwoFactorOtpFailedAttemptLimit] = useState("");
  const [twoFactorOtpFailureTimeframe, setTwoFactorOtpFailureTimeframe] = useState("");
  const [twoFactorOtpResendIntervalTimeframe, setTwoFactorOtpResendIntervalTimeframe] = useState("");
  const [twoFactorDisabledWorkspaces, setTwoFactorDisabledWorkspaces] = useState([]);
  const [twoFactorTimeframeButtonDisabled, setTwoFactorTimeframeButtonDisabled] = useState(false);

  const [userInactivityLogoutEnabled, setUserInactivityLogoutEnabled] = useState(false);
  const [userInactivityLogoutTimeframe, setUserInactivityLogoutTimeframe] = useState("");
  const [userInactivityButtonDisabled, setUserInactivityButtonDisabled] = useState(false);

  const [userDeactivationEnabled, setUserDeactivationEnabled] = useState(false);
  const [userDeactivationTimeframe, setUserDeactivationTimeframe] = useState("");
  const [userDeactivationButtonDisabled, setUserDeactivationButtonDisabled] = useState(false);

  const [userDeletionEnabled, setUserDeletionEnabled] = useState(false);
  const [userDeletionTimeframe, setUserDeletionTimeframe] = useState("");
  const [userDeletionButtonDisabled, setUserDeletionButtonDisabled] = useState(false);

  const isMounted = useMounted();

  const onTwoFactorTimeframeSubmit = async () => {
    try {
      setTwoFactorTimeframeButtonDisabled(true);
      const message = await manageSiteSettings(
        {
          two_factor_enabled: twoFactorEnabled,
          two_factor_disabled_admin: twoFactorDisabledAdmin,
          two_factor_timeframe: twoFactorTimeframe,
          two_factor_otp_block_enabled: twoFactorOtpBlockEnabled,
          two_factor_otp_failed_attempt_limit: twoFactorOtpFailedAttemptLimit,
          two_factor_otp_failure_timeframe: twoFactorOtpFailureTimeframe,
          two_factor_otp_resend_interval_timeframe: twoFactorOtpResendIntervalTimeframe,
          two_factor_disabled_workspaces: twoFactorDisabledWorkspaces,
        },
        "settings/users/multi-factor-email-authentication",
      );
      toast.success(message);
      setTwoFactorTimeframeButtonDisabled(false);
    } catch (error) {
      setTwoFactorTimeframeButtonDisabled(false);
      toast.error(error?.message);
    }
  };

  const onUserInactivitySubmit = async () => {
    try {
      setUserInactivityButtonDisabled(true);
      const message = await manageSiteSettings(
        {
          user_inactivity_logout_enabled: userInactivityLogoutEnabled,
          user_inactivity_logout_timeframe: userInactivityLogoutTimeframe,
        },
        "settings/users/inactivity-auto-logout",
      );
      toast.success(message);
      setUserInactivityButtonDisabled(false);
    } catch (error) {
      setUserInactivityButtonDisabled(false);
      toast.error(error?.message);
    }
  };

  const onUserDeactivationSubmit = async () => {
    try {
      setUserDeactivationButtonDisabled(true);
      const message = await manageSiteSettings(
        {
          user_deactivation_enabled: userDeactivationEnabled,
          user_deactivation_timeframe: userDeactivationTimeframe,
        },
        "settings/users/automatic-user-deactivation",
      );
      toast.success(message);
      setUserDeactivationButtonDisabled(false);
    } catch (error) {
      setUserDeactivationButtonDisabled(false);
      toast.error(error?.message);
    }
  };

  const onUserDeletionSubmit = async () => {
    try {
      setUserDeletionButtonDisabled(true);
      const message = await manageSiteSettings(
        {
          user_deletion_enabled: userDeletionEnabled,
          user_deletion_timeframe: userDeletionTimeframe,
        },
        "settings/users/automatic-user-deletion",
      );
      toast.success(message);
      setUserDeletionButtonDisabled(false);
    } catch (error) {
      setUserDeletionButtonDisabled(false);
      toast.error(error?.message);
    }
  };

  const initailizeTwoFactorFieldsValue = () => {
    setTwoFactorEnabled(site.two_factor_enabled || false);
    setTwoFactorDisabledAdmin(site.two_factor_disabled_admin || false);
    setTwoFactorTimeframe(site.two_factor_timeframe || "");
    setTwoFactorOtpBlockEnabled(site.two_factor_otp_block_enabled || false);
    setTwoFactorOtpFailedAttemptLimit(site.two_factor_otp_failed_attempt_limit || "");
    setTwoFactorOtpFailureTimeframe(site.two_factor_otp_failure_timeframe || "");
    setTwoFactorOtpResendIntervalTimeframe(site.two_factor_otp_resend_interval_timeframe || "");
    setTwoFactorDisabledWorkspaces(site.two_factor_disabled_workspaces || []);
  };

  const initailizeUserInactivityLogoutFieldsValue = () => {
    setUserInactivityLogoutEnabled(site.user_inactivity_logout_enabled || false);
    setUserInactivityLogoutTimeframe(site.user_inactivity_logout_timeframe || "");
  };

  const initailizeUserDeactivationFieldsValue = () => {
    setUserDeactivationEnabled(site.user_deactivation_enabled || false);
    setUserDeactivationTimeframe(site.user_deactivation_timeframe || "");
  };

  const initailizeUserDeletionFieldsValue = () => {
    setUserDeletionEnabled(site.user_deletion_enabled || false);
    setUserDeletionTimeframe(site.user_deletion_timeframe || "");
  };

  useEffect(() => {
    if (site?._id) {
      initailizeTwoFactorFieldsValue();
      initailizeUserInactivityLogoutFieldsValue();
      initailizeUserDeactivationFieldsValue();
      initailizeUserDeletionFieldsValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest("post", "/workspaces/list", {});
      const data = res.data;
      if (isMounted.current && data.status === 200) {
        setWorkspaces(data?.data || []);
      }
    };
    loadWorkspaces();
  }, [isMounted]);

  return (
    <>
      <Section>
        <div className="grid w-full gap-y-4">
          <SettingsContainer
            defaultTitle={{
              title: "Multi-factor Email Authentication (2FA)",
              subTitle: "Require email 2FA and set the duration for which devices can be remembered.",
              inputFirstTitle: "Email 2FA is required after",
              inputSecondTitle: "without a login.",
            }}
            isEnabled={twoFactorEnabled}
            setIsEnabled={setTwoFactorEnabled}
            timeFrame={twoFactorTimeframe}
            setTimeFrame={setTwoFactorTimeframe}
            isButtonDisabled={twoFactorTimeframeButtonDisabled}
            onSubmit={onTwoFactorTimeframeSubmit}
            onCancel={initailizeTwoFactorFieldsValue}
            is2Fa={true}
            site={site}
            additionalData={{
              twoFactorDisabledAdmin,
              setTwoFactorDisabledAdmin,
              twoFactorOtpBlockEnabled,
              setTwoFactorOtpBlockEnabled,
              twoFactorOtpFailedAttemptLimit,
              setTwoFactorOtpFailedAttemptLimit,
              twoFactorOtpFailureTimeframe,
              setTwoFactorOtpFailureTimeframe,
              twoFactorOtpResendIntervalTimeframe,
              setTwoFactorOtpResendIntervalTimeframe,
              workspaces,
              twoFactorDisabledWorkspaces,
              setTwoFactorDisabledWorkspaces,
            }}
          />
          <SettingsContainer
            defaultTitle={{
              title: `Inactivity auto-logout`,
              subTitle: `Define how long a user may be inactive.`,
              inputBoxInlineLabel: "minute(s)",
              inputFirstTitle: `Log a user out after`,
              inputSecondTitle: ` spent inactive.`,
            }}
            isEnabled={userInactivityLogoutEnabled}
            setIsEnabled={setUserInactivityLogoutEnabled}
            timeFrame={userInactivityLogoutTimeframe}
            setTimeFrame={setUserInactivityLogoutTimeframe}
            isButtonDisabled={userInactivityButtonDisabled}
            onSubmit={onUserInactivitySubmit}
            onCancel={initailizeUserInactivityLogoutFieldsValue}
          />
          <SettingsContainer
            defaultTitle={{
              title: `Automatic User Deactivation`,
              subTitle: `(Excluding admins)`,
              inputFirstTitle: `Deactivate users after`,
              inputSecondTitle: ` without  login.`,
            }}
            isEnabled={userDeactivationEnabled}
            setIsEnabled={setUserDeactivationEnabled}
            timeFrame={userDeactivationTimeframe}
            setTimeFrame={setUserDeactivationTimeframe}
            isButtonDisabled={userDeactivationButtonDisabled}
            onSubmit={onUserDeactivationSubmit}
            onCancel={initailizeUserDeactivationFieldsValue}
          />
          <SettingsContainer
            defaultTitle={{
              title: `Automatic User Deletion`,
              subTitle: `(Excluding admins)`,
              inputFirstTitle: `Permanently delete users after`,
              inputSecondTitle: ` without  login.`,
            }}
            isEnabled={userDeletionEnabled}
            setIsEnabled={setUserDeletionEnabled}
            timeFrame={userDeletionTimeframe}
            setTimeFrame={setUserDeletionTimeframe}
            isButtonDisabled={userDeletionButtonDisabled}
            onSubmit={onUserDeletionSubmit}
            onCancel={initailizeUserDeletionFieldsValue}
          />
        </div>
      </Section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(UserSettings);
