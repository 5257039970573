import { useIsPresent } from "framer-motion";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { deletePageCredentialOptions } from "src/actions/page";
import { workspaceIntegrationCredentialManage } from "src/actions/workspace";
import NoneFound from "src/components/Shared/NoData/NoneFound";
import WorkspaceIntegrationClientsItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationClientsItem";

const WorkspaceIntegrationClients = ({
  size,
  user,
  workspaceId,
  deletePageCredentialOptions,
  integration,
  workspaceDetails,
  editCredential = () => {},
  openCreatedByModal = () => {},
  removeCredential = () => {},
  makeDefaultButtonDisabled = false,
  setMakeDefaultButtonDisabled = () => {},
  refreshIntegrations = () => {},
  workspaces = [],
  openPageListModal = () => {},
  ...props
}) => {
  const { id } = useParams();
  let isPresent = useIsPresent();
  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };
  const [credentials, setCredentials] = useState([]);

  useEffect(() => {
    setCredentials(integration?.integrations);
  }, [integration]);

  if (id && !workspaceDetails?._id) {
    return <></>;
  }

  return (
    <div className="relative z-0 mt-2 space-y-2 rounded border-gray-200 bg-white">
      {credentials?.length > 0 ? (
        credentials.map((credential, index) => {
          return (
            // <motion.div
            //   key={credential._id}
            //   layout={true}
            //   initial={{ opacity: 0 }}
            //   animate={{ opacity: 1 }}
            //   exit={{ opacity: 0 }}
            //   transition={{ opacity: { duration: 0.3 } }}
            //   style={{ ...generateStyle, zIndex: credentials?.length - index, position: "relative" }}
            //   className={classNames(
            //     credentials?.find((cred) => !cred?.workspace?._id && cred?.integration_type === "JWT_FULL_EMBED") && credential?.integration_type === "JWT_FULL_EMBED" && !workspaceId && credential?.workspace?._id && integration?.code === "domo"
            //       ? "relative pl-6 before:absolute before:-top-[calc(50%+8px)] before:left-2 before:h-[calc(100%+8px)] before:border-l-2 before:border-gray-200 before:content-[''] after:absolute after:left-2 after:top-1/2 after:w-4 after:-translate-y-1/2 after:border-b-2 after:border-gray-200 after:content-[''] sm:pl-10 sm:before:left-5 sm:after:left-5 sm:after:w-5"
            //       : "",
            //   )}>
            <WorkspaceIntegrationClientsItem
              size={size}
              key={credential._id}
              integration={integration}
              user={user}
              workspaceId={workspaceId}
              credential={credential}
              editCredential={editCredential}
              openCreatedByModal={openCreatedByModal}
              removeCredential={removeCredential}
              makeDefaultButtonDisabled={makeDefaultButtonDisabled}
              setMakeDefaultButtonDisabled={setMakeDefaultButtonDisabled}
              refreshIntegrations={refreshIntegrations}
              workspaces={workspaces}
              openPageListModal={openPageListModal}
            />
            // </motion.div>
          );
        })
      ) : (
        <NoneFound />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
    workspace: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialManage, deletePageCredentialOptions })(WorkspaceIntegrationClients);
